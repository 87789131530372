import React, { useState } from 'react';

const HashtagInput = ({ onSubmit }) => {
    const [input, setInput] = useState('');

    const handleChange = (e) => {
        setInput(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const hashtags = input.match(/#[a-z0-9_]+/gi);
        onSubmit(input, hashtags);
        setInput('');
    };

    return (
        <form onSubmit={handleSubmit}>
            <textarea value={input} onChange={handleChange} placeholder="Write something with #hashtags" />
            <button type="submit">Post</button>
        </form>
    );
};

export default HashtagInput;